// This file contains styling that I couldn't figure out how to accomplish
// using chakra's theming tools. Perhaps someday I'll figure it out, or some
// kind soul will point me in the right direction.

.event:nth-child(odd) {
    .eventBody {
        text-align: left;
        align-items: flex-start;
        .circle {
            right: auto;
            left: -44px;
        }
        .eventBadge {
            left: auto;
            right: 0;
        }
    }
    &:after {
        right: auto;
        left: -7.5px;
        box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
    }
}

@media only screen and (max-width: 30em) {
    .event:nth-child(odd) {
        .eventBody {
            text-align: center;
        }
    }
}
